import React from "react";
import { Route, Routes as RRoutes } from "react-router-dom";
import ChangeLogList from "./components/changeLog/ChangeLogList";
import ContactUs from "./components/contact-us/ContactUs";
import Documentation from "./components/documentation/Documentation";
import Home from "./components/home/Home";

const App: React.FC = () => {
  return (
    <RRoutes>
      <Route path={"/"} element={<Home />} />
      <Route path={"/contact-us"} element={<ContactUs />} />
      <Route path={"/documentation"} element={<Documentation />} />
      <Route path={"/change-log"} element={<ChangeLogList />} />
    </RRoutes>
  );
};

export default App;
