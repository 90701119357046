type Config = {
    baseApi: string;
};

const config: Config = {
    baseApi: __OA_BASE_API__,
};

export default config;


