import React, { useState } from "react";
import { Element } from "react-scroll";

import { requestDemoEmailApi } from "@/app/api";

const RequestDemo: React.FC = () => {
    const [email, setEmail] = useState("");
    const [contactNumber, setContactNumber] = useState("");
    const [sending, setSending] = useState(false);
    const [sent, setSent] = useState(false);
    const [error, setError] = useState(false);

    async function requestDemo() {
        if (!email || !contactNumber) return;

            setSent(false);
            setError(false);
            setSending(true);

            const response = await fetch(`${requestDemoEmailApi}?emailAddress=${encodeURIComponent(email)}&contactNumber=${encodeURIComponent(contactNumber)}`);

            if (response.ok) setSent(true);
            else setError(true);

            setSending(false);
    }

    return (
        <div className="container section">
            <div className="row">
                <div className="col text-center pb-5">
                    <Element name="request-demo-section">
                        <h2 className="border-bottom border-secondary d-inline border-width-2">DEMO</h2>
                    </Element>
                </div>
            </div>
            <div className="row">
                <div className="col text-center pb-5">
                    <p>
                        If you are interested in <span className="text-secondary">One Advisor</span> please let us know
                        and we will contact you to set up a demo at your convenience
                    </p>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-7 text-center pb-5">
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                    />
                    <br />
                    <input
                        type="tel" 
                        className="form-control"
                        placeholder="Enter your contact number"
                        onChange={(e) => {
                            const numericValue = e.target.value.replace(/[^+0-9]/g, '');
                            setContactNumber(numericValue);
                        }}
                        value={contactNumber}
                    />
                    <br />
                    <button
                        className="btn btn-secondary"
                        type="button"
                        title="test"
                        onClick={requestDemo}
                        disabled={sending || email == "" || contactNumber == ""}
                    >
                        {sending ? "SENDING..." : "REQUEST DEMO"}
                    </button>
                </div>
            </div>

            <div className="row">
                <div className="col text-center pb-5">
                    {sent && (
                        <div className="alert alert-success" role="alert">
                            Thank you for requesting a demo, we will contact you to schedule a meeting.
                        </div>
                    )}
                    {error && (
                        <div className="alert alert-danger" role="alert">
                            An error occured requesting a demo.
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default RequestDemo;
