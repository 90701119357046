import React from "react";

import hvm from "../../media/partners/hvm.png";
import smithAndBormann from "../../media/partners/smith-bormann.png";
import bekker from "../../media/partners/bekker.png";
import sweidan from "../../media/partners/sweidan.png";
import curnow from "../../media/partners/curnow.png";
import dcj from "../../media/partners/dcj.png";
import biddulph from "../../media/partners/biddulph.png";

const Partners: React.FC = () => {
    return (
        <div className="bg-primary text-light pt-4">
            <div className="container section">
                <div className="row">
                    <div className="col text-center">
                        <h2>PARTNERS</h2>
                    </div>
                </div>
                <div className="row bg-white" style={{padding: 10, borderRadius:7}}>
                <table>
                    <tr>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://hvm.co.za/" target="_blank">
                            <img alt="HVM" src={hvm} height="100px" />
                        </a>
                    </div></td>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://smithbormann.co.za/" target="_blank">
                            <img alt="Smith and Bormann" src={smithAndBormann} height="100px" />
                        </a>
                    </div></td>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://bekkerinvestments.co.za/" target="_blank">
                            <img alt="Bekker Investments" src={bekker} height="100px" />
                        </a>
                    </div></td>
                        <td><div className="col-auto" style={{padding:10}}>
                        <img alt="Biddulph Financial Services" src={biddulph} height="100px" />
                    </div></td>
                    </tr>
                    <tr>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://curnowkzn.co.za/" target="_blank">
                            <img alt="Curnow KZN" src={curnow} height="100px" />
                        </a>
                    </div></td>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://dcjfinancialservices.com/" target="_blank">
                            <img alt="DCJ Financial Services" src={dcj} height="100px" />
                        </a>
                    </div></td>
                        <td><div className="col-auto" style={{padding:10}}>
                        <a href="https://sweidanandco.co.za/" target="_blank">
                            <img alt="Sweidan & Co" src={sweidan} height="100px" />
                        </a>
                    </div></td>
                    </tr>
                    </table>
                </div>
                </div>
            </div>
    );
};

export default Partners;
