import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/theme.css";
import "./styles/index.css";

import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const router = createBrowserRouter([
  {
    path: "*",
    element: <App />,
  },
]);

// import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
// import { ApplicationInsights } from "@microsoft/applicationinsights-web";

// if (__APP_INSIGHTS_KEY__ != "") {
//     const reactPlugin = new ReactPlugin();
//     const appInsights = new ApplicationInsights({
//         config: {
//             instrumentationKey: __APP_INSIGHTS_KEY__,
//             extensions: [reactPlugin],
//         },
//     });
//     appInsights.loadAppInsights();
// }

// if (__GOOGLE_ANALYTICS_KEY__ != "") {
//     ReactGA.initialize(__GOOGLE_ANALYTICS_KEY__);
//     ReactGA.pageview(window.location.pathname + window.location.search);
// }

const container = document.getElementById("root") as Element;
const root = createRoot(container);
root.render(<RouterProvider router={router} />);
