import React from "react";

import lightTheme from "@/media/themes/light_theme.png";
import darkTheme from "@/media/themes/dark_theme.png";
import compactTheme from "@/media/themes/compact_theme.png";

const Modules: React.FC = () => {
  return (
    <div className="section">
      <div className="row">
        <div className="col text-center pb-5">
          <h2 className="border-bottom border-secondary d-inline border-width-2">
            ONE ADVISOR THEMES
          </h2>
        </div>
      </div>

      <div className="row justify-content-md-center">
        <div className="col-md-6 text-center">
          <p>
            The <span className="text-secondary">One Advisor</span> application
            currently supports 3 themes, Light, Dark and Compact.
          </p>
        </div>
      </div>

      <div className="row mt-1 justify-content-md-center">
        <div className="col-md-4 py-md-3 px-md-4">
          <div className="row">
            <div className="col text-center">
              <p className="font-weight-bold pt-4 mb-0">
                LIGHT
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                alt="Light Theme"
                src={lightTheme}
                width="100%"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 py-md-3 px-md-4">
          <div className="row">
            <div className="col text-center">
              <p className="font-weight-bold pt-4 mb-0">DARK</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                alt="Dark Theme"
                src={darkTheme}
                width="100%"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
        <div className="col-md-4 py-md-3 px-md-4">
          <div className="row">
            <div className="col text-center">
              <p className="font-weight-bold pt-4 mb-0">
                COMPACT
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <img
                alt="Compact Theme"
                src={compactTheme}
                width="100%"
                className="rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
